
























  import { Validator } from '@/constant/Mixins';
  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { UserModel } from '@/store/auth/types';
  import { ProductSummaryModel } from '@/store/product/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'detailPropertiCompare',
    components: {
      DetailProduct: () => import('@/components/ProductDetail.vue'),
      ReviewProduct: () => import('@/components/review/reviewProduct.vue'),
    },
  })
  export default class detailPropertiCompare extends Mixins(Validator) {
    @Action('GetSummary', { namespace: 'product/properti' }) GetSummary!: (quotation_id: string, params?: any) => Promise<ServerResponse>;

    @State('user', { namespace: 'auth' }) user!: UserModel;
    @State('summary', { namespace: 'product/properti' }) summary!: ProductSummaryModel;

    public quotation_id: any = '';

    public get total() {
      return parseFloat(this.summary.product.basic_premi) + parseFloat(this.summary.product.admin_fee);
    }

    public created(): void {
      /**
       * array index:
       * - 0 => type
       * - 1 => product
       * - 2 => actions
       */
      let match = /compare\/([a-z-]+)\/([a-z]+).+/g.exec(this.$route.path) || [];

      this.quotation_id = this.$route.query.q;
      if (typeof this.quotation_id === 'string') this.init();
      else {
        this.$router.push({ name: `compare-${match[1]}` });
      }
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * init
     */
    public init() {
      this.GetSummary(this.quotation_id);
    }

    /**
     * Buy
     */
    public Buy() {
      if (this.user.email && this.user.phone) {
        this.$router.push({ name: 'info-item-properti', query: this.$route.query });
      } else {
        localStorage.setItem('redirect', JSON.stringify({ name: this.$route.name, query: this.$route.query }));
        Toaster.Warning('Login diperlukan!');
        this.$router.push({ name: 'login' });
      }
    }
  }
